<div class="modal-wrapper p-6" [formGroup]="userForm">
  <div class="modal-header d-flex justify-between">
    <div>
      <h2 class="mat-subtitle-1 mb-0 text">
        {{ isEditMode ? "Edit Users" : "Add Users" }}
      </h2>
      <p class="textXS mt-2 text">
        {{
          isEditMode
            ? "Enter details for edit users"
            : "Enter details for add users"
        }}
      </p>
    </div>
    <mat-icon class="pointer" (click)="close()">close</mat-icon>
  </div>
  <div class="content-wrapper my-4">
    <input formControlName="userID" type="hidden" />
    <div class="col s12 m6 l6 mb-6">
      <p class="mat-body-1 text mb-1 input-label">
        Name <span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <input
          appCapitalizeFirstLetter
          matInput
          placeholder="Enter here"
          formControlName="name"
        />
      </mat-form-field>
      <div *ngIf="userForm.get('name')?.touched || userForm.get('name')?.dirty">
        <div
          class="required-star mt-1"
          *ngIf="
            !userForm.get('name')?.hasError('required') &&
            userForm.get('name')?.hasError('whitespace')
          "
        >
          Field cannot be empty or contain only whitespace.
        </div>
      </div>
    </div>
    <div class="input-wrapper mb-6">
      <div class="d-flex align-center mb-2">
        <p class="mat-body-1 mb-0 font-weight-500 input-label">
          Email <span class="required-star"> * </span>
        </p>
      </div>
      <mat-form-field subscriptSizing="dynamic" appearance="outline">
        <input
          matInput
          placeholder="Enter here"
          type="email"
          formControlName="email"
          id="email"
          name="email"
          [style.cursor]="isEditMode ? 'not-allowed' : 'auto'"
        />
      </mat-form-field>
      <div
        *ngIf="
          userForm.controls['email'].invalid &&
          userForm.controls['email'].touched
        "
      >
        <span
          class="required-star"
          *ngIf="userForm.controls['email'].errors?.['required']"
        >
          Email is required.
        </span>
        <span
          class="required-star"
          *ngIf="userForm.controls['email'].errors?.['email']"
        >
          Email is not valid.
        </span>
      </div>
      <div class="required-star mt-2" *ngIf="errorMessage">
        {{ errorMessage }}
      </div>
    </div>
    <div class="input-wrapper mb-6">
      <div class="d-flex align-center mb-2">
        <p class="mat-body-1 mb-0 font-weight-500 input-label">
          Password <span class="required-star"> * </span>
        </p>
      </div>
      <mat-form-field subscriptSizing="dynamic" appearance="outline">
        <input
          matInput
          placeholder="Enter here"
          type="password"
          id="password"
          formControlName="password"
          name="password"
          [style.cursor]="isEditMode ? 'not-allowed' : 'auto'"
        />
      </mat-form-field>
    </div>
    <div class="input-wrapper mb-4">
      <p class="mat-body-1 text mb-1 font-weight-500 input-label">
        Portal Type <span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-select placeholder="Select" formControlName="portolType">
          <mat-option value="admin">Admin</mat-option>
          <mat-option value="customer">Customer</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      class="input-wrapper mb-4"
      *ngIf="userForm.get('portolType')?.value === 'customer'"
    >
      <p class="mat-body-1 text mb-1 font-weight-500 input-label">
        Account<span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-select placeholder="Select" formControlName="associatedAccountID">
          <mat-option
            *ngFor="let account of accounts"
            [value]="account.accountID"
          >
            {{ account.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div
        *ngIf="
          userForm.controls['associatedAccountID'].invalid &&
          userForm.controls['associatedAccountID'].touched
        "
      >
        <span
          class="required-star"
          *ngIf="userForm.controls['associatedAccountID'].errors?.['required']"
        >
          Account is required.
        </span>
      </div>
    </div>

    <div
      class="input-wrapper mb-4"
      *ngIf="userForm.get('portolType')?.value === 'admin'"
    >
      <p class="mat-body-1 text mb-1 font-weight-500 input-label">
        Role <span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-select placeholder="Select" formControlName="userRoleID">
          <mat-option
            *ngFor="let role of roleAndPermission"
            [value]="role.userRoleID"
          >
            {{ role.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div
        *ngIf="
          userForm.controls['userRoleID'].invalid &&
          userForm.controls['userRoleID'].touched
        "
      >
        <span
          class="required-star"
          *ngIf="userForm.controls['userRoleID'].errors?.['required']"
        >
          Role is required.
        </span>
      </div>
    </div>
  </div>
  <div class="modal-footer mt-2 d-flex justify-end">
    <sft-button
      [style.cursor]="isButtonDisabled ? 'not-allowed' : 'pointer'"
      [label]="isEditMode ? 'Update' : 'Save'"
      [color]="'primary'"
      [variant]="'flat'"
      [disableRipple]="false"
      [size]="'large'"
      [disable]="isButtonDisabled"
      (click)="isButtonDisabled ? null : onSave()"
    ></sft-button>
  </div>
</div>
